import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';

export default class CountryService extends Service {
	async getAllCountries(): Promise<Api.Country.Res.AllCountries> {
		let response = await http.get<RsResponseData<Api.Country.Res.AllCountries>>('country/all');
		return response.data.data;
	}
	async getCountry(countryCode: string): Promise<Api.Country.Res.Country> {
		let response = await http.get<RsResponseData<Api.Country.Res.Country>>('country', {
			countryCode
		});
		return response.data.data;
	}
	async getStates(countryCode: string): Promise<Api.Country.Res.States> {
		let response = await http.get<RsResponseData<Api.Country.Res.States>>('country/states', {
			countryCode
		});
		let res: Api.Country.Res.States = {
			...response.data.data,
			states: response.data.data.states.filter(
				(element) => !element.isoCode.startsWith('UM-') && !(element.isoCode === 'MP')
			)
		};
		return res;
	}
	async getCities(stateCode: string): Promise<Api.Country.Res.Cities> {
		let response = await http.get<RsResponseData<Api.Country.Res.Cities>>('country/cities', {
			stateCode
		});
		return response.data.data;
	}
}
