import http from '../../utils/http';
import SparkMD5 from 'spark-md5';
import { RsResponseData } from '@bit/redsky.framework.rs.http';
import { Service } from '../Service';
import globalState, {
	clearPersistentState,
	getRecoilExternalValue,
	KEY_PREFIX,
	setRecoilExternalValue
} from '../../state/globalState';

export default class UserService extends Service {
	async logUserResortSelectedCampaign(
		userId: string | number,
		campaignData: Api.Destination.Res.AvailabilitySummary
	) {
		let axiosResponse = await http.post<any>('user/selected/campaign', {
			userId,
			campaignData
		});
		return axiosResponse;
	}

	async travelPreferencesMenu() {
		let axiosResponse = await http.get<any>('user/travelpreferencesmenu');
		return axiosResponse;
	}

	async getTierDetails() {
		let axiosResponse = await http.get<RsResponseData<Api.User.Res.TierDetails>>('tier/gettierdetails');
		return axiosResponse.data.data;
	}

	async userTravelPreferences(userId: number | undefined) {
		let axiosResponse = await http.get<any>('user/mypreferences?userid=' + userId);
		return axiosResponse;
	}

	async userFavoriteDestinations(userId: number | undefined) {
		let axiosResponse = await http.get<any>('user/favoritedestinations?userid=' + userId);
		return axiosResponse;
	}

	async saveMyTravelPreferences(preferences: string, user: number | undefined) {
		let axiosResponse = await http.post<any>('user/savetravelpreferences', {
			user,
			preferences
		});
		return axiosResponse;
	}

	async saveMyFavoriteDestinations(preferences: string, user: number | undefined) {
		let axiosResponse = await http.post<any>('/user/savefavoritedestinations', {
			user,
			preferences
		});
		return axiosResponse;
	}

	async loginUserByPassword(username: string, password: string) {
		password = SparkMD5.hash(password);
		let axiosResponse = await http.post<RsResponseData<Api.User.Res.Detail>>('user/login', {
			username,
			password
		});
		await this.onAfterLogin(axiosResponse.data.data);
		return axiosResponse;
	}

	async loginUserByPasswordOtpAuth(username: string, password: string) {
		password = SparkMD5.hash(password);
		let axiosResponse = await http.post<RsResponseData<Api.User.Res.Detail>>('user/loginOtpAuth', {
			username,
			password
		});
		// await this.onAfterLogin(axiosResponse.data.data);
		return axiosResponse;
	}

	async loginUserByPasswordOtpAuthData(axiosResponse: any) {
		await this.onAfterLogin(axiosResponse);
		return axiosResponse;
	}

	async loginUserByToken(token: string) {
		let axiosResponse = await http.get<RsResponseData<Api.User.Res.Detail>>('user/with/token?token=' + token);
		await this.onAfterLogin(axiosResponse.data.data);
	}

	async requestPasswordByEmail(primaryEmail: string): Promise<Api.User.Res.ForgotPassword> {
		let response = await http.post<RsResponseData<Api.User.Res.ForgotPassword>>('user/password/forgot', {
			primaryEmail
		});
		return response.data.data;
	}

	async resetPasswordByGuid(passwordResetGuid: string, newPassword: string): Promise<Api.User.Res.ResetPassword> {
		newPassword = SparkMD5.hash(newPassword);
		let response = await http.put<RsResponseData<Api.User.Res.ResetPassword>>('user/password/reset', {
			passwordResetGuid,
			newPassword
		});
		return response.data.data;
	}

	async guidValidation(guid: string): Promise<Api.User.Res.ValidateGuid> {
		let response = await http.put<RsResponseData<Api.User.Res.ValidateGuid>>('user/password/guid/valid', {
			guid
		});
		return response.data.data;
	}

	async createNewUser(user: Api.User.Req.Create): Promise<Api.User.Res.Detail> {
		user.password = SparkMD5.hash(user.password);
		let response = await http.post<RsResponseData<Api.User.Res.Detail>>('user', user);
		return response.data.data;
	}
	async sendOtp(data: Api.User.Req.Update): Promise<Api.User.Res.Detail> {
		let response = await http.post<RsResponseData<Api.User.Res.Detail>>('user/send/mail/verification/otp', data);
		// setRecoilExternalValue<Api.User.Res.Detail | undefined>(globalState.user, response.data.data);
		return response.data.data;
	}

	async verifyUserOtp(data: any): Promise<Api.User.Res.Detail> {
		let response = await http.post<RsResponseData<Api.User.Res.Detail>>('user/otp/verified', data);
		// setRecoilExternalValue<Api.User.Res.Detail | undefined>(globalState.user, response.data.data);
		return response.data.data;
	}

	async saveSearchHistory(data: any): Promise<Api.User.Res.saveSearchHistory> {
		let response = await http.post<RsResponseData<Api.User.Res.saveSearchHistory>>('user/saveSearchHistory', data);
		return response.data.data;
	}

	async updateSaveSearchActivity(data: any): Promise<Api.User.Res.saveSearchHistory> {
		let response = await http.put<RsResponseData<Api.User.Res.saveSearchHistory>>(
			'user/updateSaveSearchActivity',
			data
		);
		return response.data.data;
	}

	async getUnknownUserSearchHistory(data: string): Promise<any> {
		let response = await http.get<any>('user/getUserSearchHistory', {
			sessionId: data
		});
		return response?.data?.data?.sessionSearchHistory;
	}

	async getUserSearchHistory(): Promise<any> {
		let response = await http.get<any>('user/getUserSearchHistory/by/user');
		return response.data.data;
	}

	async refreshUser(): Promise<void> {
		const user = getRecoilExternalValue<Api.User.Res.Detail | undefined>(globalState.user);
		if (!user) return;
		this.loginUserByToken(user.token).catch(console.error);
	}

	async update(data: Api.User.Req.Update): Promise<Api.User.Res.Detail> {
		let response = await http.put<RsResponseData<Api.User.Res.Detail>>('user', data);
		setRecoilExternalValue<Api.User.Res.Detail | undefined>(globalState.user, response.data.data);
		return response.data.data;
	}

	logout() {
		setRecoilExternalValue<Api.User.Res.Detail | undefined>(globalState.user, undefined);
		clearPersistentState();
		this.clearCheckoutUserFromLocalStorage();
	}

	async getPointsCost(): Promise<Api.PointsCost.Res.Get> {
		const response = await http.get<RsResponseData<Api.PointsCost.Res.Get>>('company/platform-and-variables');
		return response.data.data;
	}

	async updatePassword(data: Api.User.Req.UpdatePassword): Promise<boolean> {
		data.old = SparkMD5.hash(data.old);
		data.new = SparkMD5.hash(data.new);
		let response = await http.put<RsResponseData<boolean>>('user/password', data);
		return response.data.data;
	}

	setCheckoutUserInLocalStorage(checkoutUser: Misc.Checkout) {
		setRecoilExternalValue<Misc.Checkout | undefined>(globalState.checkoutUser, checkoutUser);
	}

	clearCheckoutUserFromLocalStorage() {
		const recoilCheckoutUser = getRecoilExternalValue<Misc.Checkout | undefined>(globalState.checkoutUser);
		if (!!recoilCheckoutUser) {
			setRecoilExternalValue<Misc.Checkout | undefined>(globalState.checkoutUser, undefined);
			localStorage.removeItem(KEY_PREFIX + globalState.checkoutUser.key);
		}
	}

	private async onAfterLogin(user: Api.User.Res.Detail) {
		let axiosConfig = http.currentConfig();
		axiosConfig.headers = { ...axiosConfig.headers, token: user.token };
		http.changeConfig(axiosConfig);
		this.clearCheckoutUserFromLocalStorage();
		setRecoilExternalValue<Api.User.Res.Detail | undefined>(globalState.user, user);
	}

	async resendVerificationMail(email: string) {
		let response = await http.get<any>('user/resend/verification/mail?email=' + email);
		return response.data;
	}
	async resendVerificationMailById(userId: string) {
		let response = await http.get<any>('user/resend/verification/mail?userId=' + userId);
		return response.data;
	}

	async verifyUser(userId: string, token: string) {
		let response = await http.get<any>('user/email/verified?userId=' + userId + '&token=' + token);
		let loginUser = await this.loginUserByToken(response.data.data.token);
		return response;
	}
}
